<template>
  <div id="content" class="content">
    <van-form label-width="90" class="myvForm" :readonly="openType == '2'" :show-error="false" @submit="onSubmit">

      <!-- <van-field  class="firstField" rows="1" autosize type="textarea" :required="true" :border="true" autocomplete="off"
        v-model="ruleForm.relatedProjects" name="relatedProjects" label="涉事项目:"
        :rules="[{ required: true, trigger: 'onBlur', message: '请输入涉事项目' }]" placeholder="请输入涉事项目" /> -->

      <van-field @click-right-icon="clickRight" :right-icon="thisProject == '1' ? '' : 'plus'" readonly
        class="firstField blue_icon" rows="1" autosize type="textarea" :required="true" :border="true"
        autocomplete="off" v-model="ruleForm.relatedProjects" name="relatedProjects" label="涉事项目:"
        placeholder="点击右侧“+”选择涉事项目" />

      <van-field v-if="openType == '1'" :required="true"
        :rules="[{ required: true, trigger: 'onChange', message: '请选择是否投诉本项目' }]" label-width="100" name="radio"
        label="是否投诉本项目">
        <template #input>
          <van-radio-group @change="thisProjectChange" v-model="thisProject" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field autocomplete="off" class="blue_icon" :required="true" clickable name="pickerValue"
        :value="ruleForm.eventTypeName" label="事件类别:" placeholder="点击选择事件类别"
        :rules="[{ required: true, trigger: 'onChange', message: '请选择事件类别' }]" @click="showPicker = true"
        right-icon="arrow-down" />

      <van-field class="DescriptionPlaceholderFormItem" :required="true" :disabled="true" name="eventDesc"
        :clearable="true" rows="1" :autosize="{ maxHeight: 30 }" :label="'事件描述:'" type="textarea">
      </van-field>
      <div class="DescriptionPlaceholder">
        {{ eventDescriptionPlaceholder }}
      </div>
      <van-field class="" name="eventDesc" maxlength="300" :clearable="true" v-model="ruleForm.eventDesc" rows="1"
        :autosize="{ maxHeight: 180 }" placeholder="请输入事件描述"
        :rules="[{ required: true, trigger: 'onBlur', message: '请输入事件描述' }]" label-width="0" :label="' '"
        type="textarea">
      </van-field>

      <van-field rows="1" autosize type="textarea" :border="true" autocomplete="off" v-model="ruleForm.serviceCompany"
        name="relatedProjects" label="所属劳务公司:" placeholder="请输入所属劳务公司" />



      <!-- <van-field label-width="140" name="radio" label="与五冶有无合同关系：">
        <template #input>
          <van-radio-group v-model="ruleForm.laborRelations" direction="horizontal">
            <van-radio name="1">有</van-radio>
            <van-radio name="2">无</van-radio>
          </van-radio-group>
        </template>
      </van-field> -->

      <van-field label-width="140" name="radio" label="与wy有无合同关系：">
        <template #input>

          <van-radio-group v-model="ruleForm.laborRelations" direction="horizontal">
            <van-radio name="1">有</van-radio>
            <van-radio name="0">无</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- <van-field autocomplete="off" :required="true" clickable name="fillingDate" :value="myFillingDate" label="填报日期"
        placeholder="点击选择填报日期" :rules="[{ required: true, trigger: 'onChange',message:'请选择填报时间' }]" @click="showCalendar = true"
        right-icon="arrow-down" /> -->


      <van-field name="uploader" class="fileUploader" label="上传附件:">
        <template #input>
          <el-upload :disabled="openType == '2'" class="upload-demo myUploader" :action="fileUrl"
            :on-success="filesSuccess" multiple :limit="5" :on-exceed="handleExceed" :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>

        </template>
      </van-field>

      <van-field name="" class="fileImgUploader" :max-count="5" label="上传图片:">
        <template #input>

          <el-upload :disabled="openType == '2'" accept="image/*" class="myUploadImg" :action="fileUrl"
            list-type="picture-card" :on-exceed="handleExceed" :on-preview="handlePictureCardPreview"
            :on-success="successFilesImg" :limit="5" :on-remove="handleRemoveImg" :file-list="fileListImg">
            <i class="el-icon-plus"></i>
          </el-upload>
        </template>
      </van-field>

      <template v-if="openType == '2'">
        <div style="display: flex; justify-content: center;align-items: center;">
          <div class="fengexian"></div>
          <div class="fengexianTitele" style=""> 处理信息</div>
          <div class="fengexian"></div>
        </div>

        <van-field class="" rows="1" autosize type="textarea" :border="true" autocomplete="off"
          v-model="ruleForm.disposalStatusName" name="disposalStatusName" label=" 处理状态:" />
        <van-field class="lastField" rows="1" autosize type="textarea" :border="true" autocomplete="off"
          v-model="ruleForm.disposalDesc" name="disposalDesc" label="处理情况:" />
        <van-field class="" rows="1" autosize type="textarea" autocomplete="off" name="disposalDesc" label="" />

      </template>

      <div class="bottom_box" v-if="openType == '1'">
        <van-button round block hairline type="info" native-type="submit">提交</van-button>
      </div>

    </van-form>

    <el-dialog class="imgDialog" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <!-- 事件类别下拉选项 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker :readonly="openType == '2'" value-key="eventTypeName" show-toolbar :columns="typeList"
        @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup>
    <!-- 日历2 -->
    <!-- <van-calendar color="#1989fa" :min-date="minDate" v-model="showCalendar" @confirm="onConfirm_calendar" /> -->


    <div class="my_vab_dialog">
      <van-dialog v-model="dialog_show" title="确定要提交吗？" @confirm="onConfirm_dialog" show-cancel-button>
        <div class="dialog_item">
          <!-- <label for="">事件标题：</label>
          <span>{{ ruleForm.title }}</span> -->
        </div>
        <div class="dialog_item">
          <!-- <label for="">填报人：</label>
          <span>{{ 'xxx' }}</span> -->
        </div>
      </van-dialog>
    </div>



    <van-action-sheet v-model="projectSheet" :title="action_sheet" class="action-sheet">
      <div class="content">
        <div class="list">

          <van-list v-model="loading" :finished="finished" :offset="1200" :finished-text="finished_text" @load="onLoad">

            <van-search class="my_search" show-action shape="round" v-model="search_value" placeholder="按关键词搜索项目">
              <template #action>
                <div @click="onSearch" style="background-color: #fff;color: #1989fa;
                border-radius: 20px;font-size: 16px;font-weight: bold;">搜索</div>
              </template>
            </van-search>

            <div style="margin-top: 20px;">
              <template v-if="projectList.length > 0">
                <van-cell v-for="item in projectList" :key="item.pcNo"
                  :class="[ruleForm.relatedProjects == item ? 'active' : '']"
                  :icon="ruleForm.relatedProjects == item ? 'success' : ''" @click="SelectProject(item)"
                  :title="item.projectName" />
              </template>

              <template v-else>


                <div style="margin-bottom: 30px;text-align: center; font-weight: bolder;">
                  <div style="margin: 10px 0;">未查询到项目!
                  </div>

                  <div style="font-size: 14px; margin: 10px 0;color: red;font-weight:300;">
                    (请确认查询信息是否准确，或手动输入项目名称)
                  </div>
                </div>

                <van-form label-width="90" class="myvForm" :readonly="openType == '2'" :show-error="false"
                  @submit="onSubmit2">
                  <van-field rows="1" :rules="[{ required: true, trigger: 'onBlur', message: '请输入涉事项目名称' }]" l autosize
                    type="textarea" :required="true" :border="true" autocomplete="off" v-model="manual_input_val"
                    name="relatedProjects" label="涉事项目:" placeholder="请手动输入涉事项目名称" />

                  <div style="margin: 70px auto 20px;">
                    <van-button round block hairline type="info" native-type="submit">确定</van-button>
                  </div>

                </van-form>

              </template>
            </div>

          </van-list>
        </div>
        <van-popup v-model="showArea" position="bottom" :style="{ height: '30%', paddingBottom: '100px' }">
          <van-area columns-num="2" @cancel="showArea = false" @confirm="areaConfirm" title="地址选择" :area-list="areaList"
            value="110101" />
        </van-popup>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

import { Toast } from 'vant';
import { baseURL } from '../utils/http';
import { areaList } from '@vant/area-data';

export default {
  props: {
    openType: {
      type: String,
      default: '1',// 1：提交表单 ， 2：查看表单
    },

    eventId: {
      type: String
    }

  },
  data() {
    return {
      manual_input_val: '',//手动输入value
      finished_text: '没有更多了',
      action_sheet: '选择项目',
      showArea: false,
      areaList,//省市区数据
      district_value: '',
      search_value: '',
      select_project: '',
      projectSheet: false,
      thisProject: '1',
      ruleForm: {
        relatedProjects: '',//涉事项目
        relatedProjectsCode: null,//项目编号
        relatedProjectsId: null, //项目id
        eventTypeName: '',
        eventDesc: '',//描述
        eventTypeId: '',
        annexImg: '',//图片
        annexFile: '', //附件
        laborRelations: null,//与wy有无合同关系  0:无； 1：有  null：默认/未填
        serviceCompany: ''
      },
      fileUrl: baseURL + '/api/upload/uploadFile',
      showPicker: false,
      typeList: [

      ],
      fileList: [],
      fileListImg: [],
      showCalendar: false,
      wxInfo: '',
      // maxDate: '',
      // minDate: '',
      // myFillingDate: '',
      eventDescriptionPlaceholder: '',
      dialog_show: false,
      dialogVisible: false,
      dialogImageUrl: '',
      defaultPhoneHeight: '',
      projectList: [],
      loading: false,
      finished: false,
      projectParameters: {
        pageSize: 15,
        pageNum: 1,
        projectName: ''
      },
      pcNO: undefined,
      projectData: '',
      total: 0
    }
  },
  created() {

    // myFormMain.style.height=50 - 10 + 'px'
    // this.setMinDate()
    this.getSalaryArrearsList();
    this.wxInfo = JSON.parse(localStorage.getItem('userInfo')) || '';
    if (this.openType == '2' && this.eventId) {
      this.ruleForm = {}
      this.getSalaryArrearsDetailsById(this.eventId);
    };
    this.eventDescriptionPlaceholder = JSON.parse(localStorage.getItem('eventDescPrompt'));

    this.getProjectDetails()
  },
  mounted() {
    this.defaultPhoneHeight = window.innerHeight;
    let myFormMain = document.getElementById('content')
    myFormMain.style.height = this.defaultPhoneHeight - 15 + 'px';


  },
  methods: {
    synchroProjectData(data) {//同步项目数据
      this.ruleForm.relatedProjects = data.projectName || ''
      this.ruleForm.relatedProjectsCode = data.pcNo || null
      this.ruleForm.relatedProjectsId = data.id || null
    },

    //根据pcNo查看项目详情
    getProjectDetails() {
      this.pcNo = localStorage.getItem('pcNo') || undefined
      this.$http(`/api/project/${this.pcNo}`).then(res => {
        if (res.code == 200) {
          this.projectData = res.data || {};
          this.synchroProjectData(this.projectData)
        }
      })
    },

    getProjectList(values) {

    return  this.$http(`/api/project/list?pageSize=${values.pageSize}&pageNum=${values.pageNum}&projectName=${values.projectName}`)
        .then(res => {
          if (res.code == 200) {
            this.projectList = res.rows || []

            this.total = res.total || 0
          }
        })
    },
    onSearch() {//搜索

      let obj = this.projectParameters;
      obj.projectName = this.search_value

      this.getProjectList(obj);

    },
    areaConfirm(e) {//地区筛选项目

      // console.log(e);
      this.finished = true

      let areaStr = ''
      e.forEach((item, index) => {

        if (index == 0) {
          areaStr = item.name;
        };

        if (0 < index) {
          if (e[index].name != e[index - 1].name) {
            areaStr += "-" + item.name;
          }
        };

      })
      this.district_value = areaStr;

      if (e[0].name == "北京市") {
        this.projectList = [];
        for (let i = 0; i < 15; i++) {
          this.projectList.push('项目' + (this.projectList.length + 1));
        }
        // this.finished=false
      }

      if (e[0].name != "北京市") {
        // console.log(1111111);
        this.projectList = [];
        this.ruleForm.relatedProjects = ''
      }

      this.showArea = false;

      if (this.projectList.length == 0) {
        this.action_sheet = ''
        this.finished_text = ''
      }
    },

    SelectProject(item) {//选择项目
      // this.ruleForm.relatedProjects = item.projectName;
      this.synchroProjectData(item)
      this.projectSheet = false;

    },

    async onLoad() {
      // 异步更新数据

      // this.finished = false
      console.log('onLoad执行了-387');
      

      await this.getProjectList(this.projectParameters);

      console.log(this.projectParameters.pageNum * this.projectParameters.pageSize, this.total);
      if (this.projectParameters.pageNum * this.projectParameters.pageSize >= this.total) {
        console.log('onLoad执行了-393');
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        this.finished = true
        return
      } else {
        console.log('onLoad执行了-400');
        this.projectParameters.pageNum++;
      }



    },

    clickRight() {

      this.projectSheet = true;
    },

    thisProjectChange(e) {//是否投诉本项目
      console.log('e', e, this.thisProject);

      if (e == '1') {//是
        this.synchroProjectData(this.projectData);
      }

      if (e == '0') {//否
        this.ruleForm.relatedProjects = '';
        this.ruleForm.relatedProjectsId = null;
        this.ruleForm.relatedProjectsCode = null;
      }
    },

    filesSuccess(file) {//文件上传成功回调

      if (this.ruleForm.annexFile == '') {
        this.ruleForm.annexFile = file.data.url
      } else {
        this.ruleForm.annexFile = this.ruleForm.annexFile + ',' + file.data.url
      }

      // file.data
    },

    successFilesImg(file) { //文件上传成功回调

      if (this.ruleForm.annexImg == '') {
        this.ruleForm.annexImg = file.data.url;
      } else {
        this.ruleForm.annexImg = this.ruleForm.annexImg + ',' + file.data.url
      }


    },
    handleExceed() { //
      this.$message.warning(`只能上传五个文件，请删除后再操作`);
    },
    handleRemoveImg() {//删除图片

    },
    handlePictureCardPreview(file) {

      this.dialogVisible = true;
      this.dialogImageUrl = file.url;

    },

    getSalaryArrearsList() {//获取事件列表
      this.$http('/api/eventType/getList').then(res => {
        this.typeList = res.data
      })
    },
    fileForEach(arr, str) {

      if (arr.length > 0) {
        arr.forEach((item) => {
          let ARR = item.split("/")
          let name = ARR[ARR.length - 1]
          this[str].push({ url: item, name })
        })
      }
    },
    getSalaryArrearsDetailsById(id) {//获取详情
      this.$http(`/api/salaryArrears/getById?id=${id}`)
        .then(res => {

          this.ruleForm = res.data;
          let imgList = res.data.annexImg;
          let fileList = res.data.annexFile
          if (res.data.annexImg) {
            imgList = imgList.split(',');
          }
          if (res.data.annexFile) {
            fileList = fileList.split(',');
          }

          this.fileForEach(imgList, 'fileListImg')
          this.fileForEach(fileList, 'fileList')

          this.typeList.forEach(item => {
            if (item.id == this.ruleForm.eventTypeId) {
              this.ruleForm.eventTypeName = item.eventTypeName
            }
          })

        })
    },

    addSalaryArrear(value) {//新增
      this.$http.post(`/api/salaryArrears/addSalaryArrears`, value).then(res => {

        const toast = Toast(res.msg || '信息提交成功！');
        setTimeout(() => {
          toast.clear();
          this.$router.go(-1);
        }, 2000)
      }).catch(err => {
        Toast(err.msg || '信息提交失败！请重试');

      })
    },
    onSubmit(values) {//提交
      this.dialog_show = true
    },

    onSubmit2(values) {//提交
      console.log(10101);

      this.ruleForm.relatedProjects = this.manual_input_val
      this.projectSheet = false
    },


    onConfirm_dialog() {
      // console.log('this.onConfirmVal', this.onConfirmVal);
      let obj = {
        wxOpenid: '',
        wxNickname: '',
        wxSex: '',
        wxProvince: '',
        wxCity: '',
        wxCountry: '',
        wxHeadimgurl: '',
        wxUnionid: '',
      }
      if (this.wxInfo) {
        obj.wxOpenid = this.wxInfo.openid || '';
        obj.wxNickname = this.wxInfo.nickname || '';
        obj.wxSex = this.wxInfo.sex || '';
        obj.wxProvince = this.wxInfo.province || '';
        obj.wxCity = this.wxInfo.city || '';
        obj.wxCountry = this.wxInfo.country || '';
        obj.wxHeadimgurl = this.wxInfo.headimgurl || '';
        obj.wxUnionid = this.wxInfo.unionid || '';

      }

      //rgba(25,137, 250, 0.7)

      let formVal = JSON.parse(localStorage.getItem('formVal'));
      let addForm = { ...this.ruleForm, ...obj }
      if (formVal) {
        addForm = { ...addForm, ...formVal }
      }

      console.log('addForm', addForm);

      this.addSalaryArrear(addForm)
      // 新增事件

    },


    onConfirm(e) {//下拉
      this.ruleForm.eventTypeName = e.eventTypeName
      this.ruleForm.eventTypeId = e.id
      this.showPicker = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.content {
  // min-height: calc(100vh - 20px);
  padding: 10px 5vw 0px;


  //is-uploading
  .van-cell::after {
    border-bottom: 1px solid #515253;

    transform: scaleY(0.3);

  }

  /deep/ .firstField textarea::placeholder {
    color: red;
  }


  .action-sheet {
    padding: 10px 0 30px;
    max-height: 60%;

    .content {
      min-height: 250px;
    }
  }

  .action-sheet .van-cell::after {
    border-bottom: 1px solid #909090;

  }

  .action-sheet .my_search .van-search__content {
    background: #f5f5f5;
  }

  .action-sheet .my_search .van-search__action {
    padding: 0;
    padding-left: 15px;
  }

  .action-sheet .van-action-sheet__header {
    // color: #1989fa;
    padding-bottom: 5px;
    font-weight: bold;
  }



  /deep/ .action-sheet [class*=van-hairline]::after {
    border: 1px solid #8a8a8a;
    border-width: 1px 0;
  }


  /deep/ .van-cell::before {
    line-height: 25px;
  }

  .imgDialog {
    margin-top: 18vh;

    /deep/ .el-dialog {
      width: 86%;
    }
  }



  .fileImgUploader::after,
  .fileUploader::after,
  .DescriptionPlaceholderFormItem::after {
    border-bottom: none;
    height: 38px;

  }

  /deep/ .fileUploader {
    .van-cell {
      line-height: 38px;
    }

    .van-field__body {
      // height: 38px;
    }
  }


  /deep/ .myvForm .blue_icon .van-field__right-icon,
  /deep/ .action-sheet .blue_icon .van-field__right-icon {
    color: #1989fa;
  }

  .myvForm .van-cell {
    min-height: 48px;
    // line-height: 59px;
    padding-bottom: 0px;
    padding: 17px 16px 10px 16px;

    /deep/ .van-field__body {
      line-height: 25px;
    }
  }

  /deep/ #content .DescriptionPlaceholderFormItem {
    min-height: 38px;
  }

  /deep/ .van-field__label {
    line-height: 25px;
  }

  /deep/ .DescriptionPlaceholderFormItem {
    line-height: 25px;
    height: 35px;
    padding-bottom: 0px;

    .van-field__label {
      color: #646566;
      line-height: 25px;
    }

    .van-field__value {
      line-height: 30px;
    }
  }



  .myUploader .el-button--primary {
    background-color: #9e9e9e;
    border: none;

  }


  /deep/ .myvForm .van-cell__title {
    font-weight: bold;

  }

  .myvForm {
    position: relative;
    // padding-top: 20px;
    // min-height: 650px;
  }

  // @media (min-aspect-ratio: 13/20) {
  //   .bottom_box {
  //     display: none;
  //   }
  // }

  .bottom_box {
    width: 140px;
    // margin: 50px auto;
    position: absolute;
    bottom: -180px;
    padding-bottom: 40px;
    // margin-bottom: 30px;
    left: calc(50% - 70px);

    .van-button--round {
      border-radius: 4px;
    }
  }

  /deep/ .myUploader .el-upload-list__item-name {
    width: 200px;
  }

  /deep/ .myUploader .el-upload-list {
    margin-left: -26px;
  }

  .DescriptionPlaceholder {
    box-sizing: border-box;
    color: #756f6f;
    font-size: 12px;
    // padding: 0 20px;
    margin: 0 15px;
    margin-bottom: 10px;
  }

  .myUploadImg {
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
      // line-height: 100px;
    }

    /deep/ .el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: 80px;
      margin-bottom: 10px;
    }

    /deep/ .el-icon-close-tip {
      display: none;
    }
  }

  /deep/ .my_vab_dialog {
    .van-dialog__header {
      font-size: 16px;
      padding-bottom: 6px;
      padding-top: 40px;
      font-weight: 900;
      height: 40px;
    }

    .van-dialog__content {
      padding: 0 20px;
    }

    .van-dialog__confirm {
      background-color: #f8cb01 !important;
      color: #000 !important;
    }

    .van-button {
      border-radius: 35px;
      width: 40%;
      -webkit-box-flex: none;
      -webkit-flex: none;
      flex: none;
      height: 40px;
    }

    .van-button--default {
      border: 1px solid #e2e2e2;
    }

    .van-dialog__footer {
      justify-content: space-around;
      padding-bottom: 20px;
    }

    .van-hairline--top::after {
      border-top-width: 0;
    }

    .van-button__text {
      font-size: 14px;
      font-weight: 600;
    }


    /deep/ .van-field__control {
      line-height: 24px;
    }

    // /deep/ .lastField ::after {
    //   border-bottom: 1px solid #515253;
    //   transform: scaleY(0.3);
    // }
  }

  // firstField
}

.fengexian {
  // width: 38%;
  flex-grow: 1;
  border-bottom: 1.5px solid #1368ad;
  height: 1px;
}

.fengexianTitele {
  font-size: 15px;
  padding: 0 10px;
  color: black;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}
</style>
<style lang="scss">
// .van-field__control {
//   line-height: 24px !important;
// }
.fileUploader,
.fileImgUploader {
  min-height: 38px !important;
  margin-bottom: 10px;
  padding: 10px 0;
}

.action-sheet {
  .list {

    .active {
      // background-color: aqua;
      color: #007eff;
      font-weight: bolder;
    }
  }
}

.bold_label {
  font-weight: bold;
}
</style>
